<template>
  <core-dialog-stepper v-model="step" persistent max-width="500" :fullscreen="isMobile" @close="$emit('close')">
    <core-dialog-step step="select_audience" title="Partager la publication">
      <app-actions-list :title="null" :actions="actions" />
    </core-dialog-step>
    <core-dialog-step step="select_team" previous-step="select_audience"
                      title="Partager la publication"
    >
      <form-category title="Partager dans un groupe" subtitle="Séléctionner un groupe pour le partage" />
      <teams-list-select v-model="teamUid" @input="onSelectTeam" />
    </core-dialog-step>
    <core-dialog-step step="select_contact" previous-step="select_audience"
                      title="Partager la publication"
    >
      <form-category title="Partager à un contact" subtitle="Séléctionner un contact pour le partage" />
      <user-contacts-list :selected.sync="contact" selectable @update:selected="onSelectContact" />
    </core-dialog-step>
    <core-dialog-step step="publish" previous-step="select_audience" title="Partager la publication">
      <feed-publication-share-form v-if="targetFeedList" :feed-list="targetFeedList"
                                   :shared-publication="sharedPublication" :audience="audience"
                                   @submitted="$emit('close')"
      />
    </core-dialog-step>
  </core-dialog-stepper>
</template>

<script>
  import AppActionsList from '@/modules/core/layout/app/AppActionsList'
  import { FeedList, Person, Publication, Space, Team } from '@/models'
  import TeamsListSelect from '@/modules/teams/components/TeamsListSelect'
  import { mapState } from 'vuex'
  import FeedPublicationShareForm from '@/modules/feeds/components/Publication/Share/FeedPublicationShareForm'
  import UserContactsList from '@/modules/user/components/UserContactsList'
  import CoreDialogStep from '@/modules/core/components/CoreDialogStep'
  import CoreDialogStepper from '@/modules/core/components/CoreDialogStepper'
  import FormCategory from '@/modules/core/layout/FormCategory'

  export default {
    name: 'FeedPublicationShareDialog',
    components: {
      CoreDialogStepper, FormCategory,
      CoreDialogStep, UserContactsList, FeedPublicationShareForm, TeamsListSelect, AppActionsList
    },
    props: {
      sharedPublication: Publication
    },
    data() {
      return {
        step: 'select_audience',
        audience: null,
        teamUid: null,
        contact: null,
        targetFeedList: null
      }
    }, computed: {
      ...mapState('layout', ['isMobile']),
      space() {
        return Space.get(this.$store.state.core.space)
      },
      spaceFeedList() {
        return FeedList.get(this.space.feedList)
      },
      actions() {
        let actions = [
          {
            label: 'Fil d\'actualité',
            subtitle: 'Partager sur votre fil d\'actualité',
            icon: 'ballot',
            action: async () => {
              this.audience = 'me'
              this.step = 'publish'
              this.targetFeedList = await Person.get(this.$auth.user.sub).fetchFeedList()
            }
          }
        ]

        if (this.$can('publish', this.spaceFeedList)) {
          actions.push({
            label: 'Espace',
            subtitle: 'Partager au sein de votre espace',
            icon: 'layer-group',
            action: async () => {
              this.audience = 'space'
              this.step = 'publish'
              this.targetFeedList = this.spaceFeedList
            }
          })
        }

        actions = actions.concat([
          {
            label: 'Groupe',
            subtitle: 'Partager dans un groupe',
            icon: 'users',
            action: () => {
              this.step = 'select_team'
              this.audience = 'team'
            }
          },
          {
            label: 'Contact',
            subtitle: 'Partager sur le profil d\'un contact',
            icon: 'address-book',
            action: () => {
              this.step = 'select_contact'
              this.audience = 'contact'
            }
          }
        ])

        return actions
      }
    },
    methods: {
      async onSelectTeam(uid) {
        this.audience = 'team'
        this.step = 'publish'
        this.targetFeedList = await Team.get(uid).fetchFeedList()
      },
      async onSelectContact(uid) {
        this.audience = 'contact'
        this.step = 'publish'
        this.targetFeedList = await Person.get(uid).fetchFeedList()
      }
    }
  }
</script>

/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from '@/api/api'

export function url(url) {
  return api.get('/v3/ws/open-graph/url', {
    params: {
      url
    }
  })
}

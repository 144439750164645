<template>
  <v-card :loading="loading" class="post-container pa-0 elevation-x"
          :class="{'publication-alert': publication.alert}"
          :outlined="publication.alert"
  >
    <v-avatar v-if="publication.alert" color="error lighten-3 circle" size="25" class="alert-badge">
      <font-awesome-icon :icon="['fad', 'exclamation']"
                         size="1x" class="white--text"
      />
    </v-avatar>
    <slot />
    <div class="post-footer mt-4 px-3">
      <div>
        <slot name="publication-actions" @share="$emit('share')" @update="$emit('update')" />

        <v-btn v-if="item.pinned && $can('unpin', feedList)" text x-small dense class="primary--text" :loading="pinning" @click="unpin">
          <font-awesome-icon :icon="['fad', 'thumbtack']" size="1x" class="primary--text mr-2" />
          Desepingler
        </v-btn>
        <v-btn v-else-if="$can('pin', feedList)" text x-small dense class="primary--text" :loading="pinning" @click="pin">
          <font-awesome-icon :icon="['fad', 'thumbtack']" size="1x" class="primary--text mr-2" />
          Epingler
        </v-btn>
      </div>
      <v-btn v-if="totalComments" text x-small dense class="primary--text" @click="commentsVisible = !commentsVisible">
        {{ totalComments }} {{ 'commentaire' | pluralize(totalComments) }}
      </v-btn>
    </div>
    <keep-alive>
      <comments-section v-if="commentsVisible" :uid="commentsSection.uid" />
    </keep-alive>
  </v-card>
</template>

<script>
  import CommentsSection from '@/modules/comments/components/CommentsSection'
  import {Publication, FeedItem, FeedList, CommentsSection as CommentsSectionModel} from '@/models'
  import actionsMixin from '@/modules/feeds/mixins/feed-publication-actions'

  export default {
    name: 'FeedPublicationContainer',
    components: {
      CommentsSection
    },
    mixins: [actionsMixin],
    inject: ['eventBus'],
    props: {
      publication: Publication,
      commentsSection: CommentsSectionModel,
      loading: Boolean,
      item: FeedItem,
      feedList: FeedList
    },
    data() {
      return {
        commentsVisible: !this.totalComments,
        sharingPublication: false
      }
    },
    computed: {
      totalComments() {
        return this.commentsSection.total
      }
    }
  }
</script>

<style scoped>
  .publication-alert {
    border-color: rgba(187, 0, 0, 0.3);
    border-width: 2px;
    border-style: solid;
  }
  .alert-badge {
    position: absolute;
    top: -8px;
    left: -8px;
    border-radius: 50%!important;
  }
</style>

<template>
  <div>
    <div v-for="uid in comments" :key="uid">
      <slot :comment="comment(uid)" />
    </div>

    <v-container v-if="loadingMore">
      <v-row justify="center">
        <v-col cols="2">
          <v-progress-linear active indeterminate rounded height="2" class="op-40" />
        </v-col>
      </v-row>
    </v-container>

    <div v-show="remaining > 0 && !loadingMore" class="show-more-comments">
      <button type="button" class="grey--text body-2 btn btn-link" @click="loadMore">
        Afficher {{ remaining }}
        {{ 'autre' | pluralize(remaining) }}
        {{ 'commentaire' | pluralize(remaining) }}
      </button>
    </div>
  </div>
</template>

<script>
  import {Comment} from '@/models'

  export default {
    name: 'CommentsList',
    props: {
      comments: {
        type: Array,
        required: true
      },
      total: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        loadingMore: false
      }
    },
    computed: {
      remaining() {
        return this.total - this.comments.length
      },
      comment() {
        return uid => Comment.get(uid)
      }
    },
    mounted() {
      this.loadMore()
    },
    methods: {
      async loadMore() {
        if (this.loadingMore || this.remaining === 0) {
          return
        }
        this.loadingMore = true
        this.$emit('load-more', () => {
          this.loadingMore = false
        })
      }
    }
  }
</script>

<template>
  <app-actions :actions="actions" />
</template>

<script>
  import AppActions from '@/modules/core/layout/app/AppActions'
  import {FeedItem, FeedList, FeedListOwner, Publication} from '@/models'
  import actionsMixin from '@/modules/feeds/mixins/feed-publication-actions'

  export default {
    name: 'FeedPublicationActions',
    components: {AppActions},
    mixins: [actionsMixin],
    props: {
      feedList: FeedList,
      item: FeedItem,
      publication: Publication
    },
    computed: {
      on() {
        return this.publication.on ? FeedList.get(this.publication.on) : null
      },
      onOwner() { // L'entité où a été écris la publication
        return this.on ? FeedListOwner.get(this.on.owner) : null
      },
      by() {
        return FeedList.get(this.publication.by)
      },
      byOwner() { // Le user ayant écris la publication partagé
        return FeedListOwner.get(this.by.owner)
      },
      actions() {
        let actions = []

        if (this.$can('share', this.publication)) {
          actions.push({
            label: 'Partager',
            action: () => {
              this.$emit('share',this.publication)
            }
          })
        }

        if (this.$can('update', this.publication)) {
          actions.push({
            label: 'Editer', action: () => {
              this.$emit('update',this.publication)
            }
          })
        }

        if (this.item.pinned && this.$can('unpin', this.feedList)) {
          actions.push({label: 'Desepingler la publication', action: this.unpin})
        } else if (!this.item.pinned && this.$can('pin', this.feedList)) {
          actions.push({label: 'Epingler la publication', action: this.pin})
        }

        if (this.$can('manage_alert', this.feedList)) {
          actions.push({
            label: this.publication.alert ? 'Ne plus marquer comme alerte' : 'Marquer comme alerte',
            action: () => this.updateAlertFlag(!this.publication.alert)
          })
        }

        if(this.$can('remove', this.publication)) {
          actions.push({label: 'Supprimer ma publication', action: ()=> this.deletePublication(this.publication)})
        }
        else if (this.$can('remove', this.feedList)) {
          if(this.on === this.feedList) {
            actions.push({label: 'Supprimer la publication', action: ()=> this.deletePublication(this.publication)})
          }
          else {
            actions.push({label: 'Masquer la publication', action: () => this.hide(this.publication)})
          }
        }

        actions = actions.concat(this.subscriptionsActions([this.by, this.on]))

        return actions
      }
    }
  }
</script>

<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-menu
    v-model="popover"
    open-on-hover
    open-delay="300"
    :close-on-content-click="false"
    transition="slide-y-transition"
    bottom
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" class="d-inline-block white-space-normal" v-on="on">{{ entity.name }}</div>
    </template>

    <v-card width="320">
      <template v-if="popData">
        <entity-pop-over-header :type="type" :pop-data="popData" />
        <v-divider />
        <v-card-text>
          <team-data v-if="type === 'team'" :pop-data="popData" :membership-data="membershipData" />
          <space-data v-else-if="type === 'space'" :pop-data="popData" :membership-data="membershipData" />
          <professional-data v-else-if="type === 'professional'" :pop-data="popData" />
          <patient-data v-else-if="type === 'patient'" :pop-data="popData" />
        </v-card-text>
        <v-divider />
        <v-card-actions v-if="type !== 'space'" class="text-center">
          <v-btn small text class="mx-auto" @click="gotoResource">Consulter</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-skeleton-loader type="list-item-avatar-two-line" class="px-1" />
        <v-divider />
        <v-skeleton-loader type="paragraph" class="py-5 px-4" />
        <v-divider />
        <v-skeleton-loader type="text" class="py-4 px-4 mx-auto" />
      </template>
    </v-card>
  </v-menu>
</template>

<script>
  import {empty} from '@/utils/variables'
  import {Team, Space, Membership, Patient, Professional} from '@/models'
  import TeamData from '@/modules/core/layout/popover/data/TeamData'
  import SpaceData from '@/modules/core/layout/popover/data/SpaceData'
  import ProfessionalData from '@/modules/core/layout/popover/data/ProfessionalData'
  import PatientData from '@/modules/core/layout/popover/data/PatientData'
  import EntityPopOverHeader from '@/modules/core/layout/popover/EntityPopOverHeader'

  export default {
    name: 'EntityPopOver',
    components: {EntityPopOverHeader, PatientData, ProfessionalData, SpaceData, TeamData},
    props: {
      entity: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loading: false,
        popover: false,
        popData: null,
        type: null,
        membershipData: null,
        route: null
      }
    },
    watch: {
      popover(opened) {
        if (opened === true) {
          this.loading = true
          this.fetchSourceData()
          this.loading = false
        }
      }
    },
    created() {
      if(empty(this.type)) {
        this.type = this.entity.type.split(':').pop()
      }
    },
    methods: {
      async fetchSourceData() {
        if(empty(this.type))
          return
        switch (this.type) {
          case 'team':
            await Team.fetchOne(this.entity.uid)
            this.popData = Team.get(this.entity.uid)
            this.membershipData = Membership.get(this.popData.membership)
            this.route = {name: 'team-profile', params: {uid: this.entity.uid}}
            break
          case 'space':
            this.popData = Space.get(this.entity.uid)
            this.membershipData = Membership.get(this.popData.membership)
            this.route = {name: 'space-profile'}
            break
          case 'patient':
            this.popData = Patient.get(this.entity.uid)
            this.route = {name: 'patient-profile', params: {uid: this.entity.uid}}
            break
          case 'professional':
            await Professional.fetchOne(this.entity.uid)
            this.popData = Professional.get(this.entity.uid)
            this.route = {name: 'professional-profile', params: {uid: this.entity.uid}}
            break
          default:
            this.popData = null
        }
      },
      gotoResource() {
        this.popover = false
        this.$router.push(this.route)
      }
    }
  }
</script>

<template>
  <v-card class="post-container is-pending elevation-x">
    <v-list-item v-if="author" class="px-0">
      <app-author :user="author" :date="publication.creation_date">
        <template v-slot:sub-right>
          <feed-publication-visibility :target-feed-list-uid="feedListUid" :visibility="publication.visibility" />
        </template>
      </app-author>
    </v-list-item>

    <div class="body publication">
      <div class="content text-display">
        <div class="w-100">
          {{ publication.message }}
        </div>
      </div>

      <!--      <feed-media-section :medias="attachments" />-->
      <feed-open-graph :data="publication.openGraph" />
    </div>
  </v-card>
</template>

<script>
  // import FeedMediaSection from '@/modules/feed/components/FeedMediaSection'
  import FeedOpenGraph from '@/modules/feeds/components/FeedOpenGraph'
  import AppAuthor from '@/modules/core/layout/app/AppAuthor'
  import FeedPublicationVisibility from '@/modules/feeds/components/Publication/FeedPublicationVisibility'
  import {Person, Publication} from '@/models'

  export default {
    name: 'FeedPublicationPending',
    components: {
      FeedPublicationVisibility,
      AppAuthor,
      FeedOpenGraph
    },
    props: {
      publication: Publication,
      feedListUid: String
    },
    computed: {
      author() {
        return Person.get(this.publication.author)
      }
    }
  }
</script>

<template>
  <span v-if="currentVisibilityData">
    <template v-if="options.length > 1">
      <v-menu v-model="menu" offset-y>
        <template v-slot:activator="{on: menuOn, attrs}">
          <v-tooltip bottom open-delay="300">
            <template v-slot:activator="{on: tooltip}">
              <v-btn v-if="buttonStyle" small rounded outlined color="primary"
                     :loading="loading"
                     v-bind="attrs" v-on="{ ...tooltip, ...menuOn }"
              >
                <font-awesome-icon class="mr-2" :icon="currentVisibilityData.icon" />
                <span>{{ currentVisibilityData.title }}</span>
                <font-awesome-icon class="ml-2" icon="caret-down" />
              </v-btn>
              <v-btn v-else x-small text class="pa-0" color="primary"
                     :loading="loading"
                     v-bind="attrs" v-on="{ ...tooltip, ...menuOn }"
              >
                <font-awesome-icon :icon="currentVisibilityData.icon" />
                <font-awesome-icon class="ml-1" icon="caret-down" />
              </v-btn>
            </template>

            {{ currentVisibilityData.subtitle }}
          </v-tooltip>
        </template>

        <v-list>
          <v-list-item v-for="option in options"
                       :key="option.feedListUid+'_'+option.visibility" class="px-2"
                       @click="selectOption(option.feedListUid, option.visibility)"
          >
            <v-list-item-avatar class="mr-2">
              <font-awesome-icon class="primary--text" size="lg" :icon="option.icon" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-subtitle-2">{{ option.title }}</v-list-item-title>
              <v-list-item-subtitle class="text-caption text-disabled">
                {{ option.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-else-if="!buttonStyle">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <font-awesome-icon :icon="currentVisibilityData.icon" v-on="tooltip" />
        </template>

        {{ currentVisibilityData.subtitle }}
      </v-tooltip>
    </template>
  </span>
</template>

<script>
  import { Publication, FeedList, FeedListOwner, Person } from '@/models'
  import visibilities, { getOptions } from '@/services/publication_visibility'

  export default {
    name: 'FeedPublicationVisibility',
    props: {
      editable: Boolean,
      loading: Boolean,
      publication: Publication,
      feedListsUid: Array,
      visibility: String,
      targetFeedListUid: String,
      buttonStyle: Boolean,
      byOwnerUid: String
    },
    data() {
      return {
        menu: false
      }
    },
    computed: {
      on() {
        return FeedList.get(this.targetFeedListUid)
      },
      onOwner() {
        return this.on ? FeedListOwner.get(this.on.owner) : null
      },
      feedList() {
        return uid => FeedList.get(uid)
      },
      feedListOwner() {
        return uid => FeedListOwner.get(uid)
      },
      currentVisibilityData() {
        return this.visibility ? this.visibilityData(this.onOwner.type, this.visibility) : null
      },
      visibilityData() {
        return (ownerType, visibility) => {
          if (typeof visibilities[ownerType] !== 'undefined' && visibilities[ownerType][visibility]) {
            return visibilities[ownerType][visibility]
          }
          // eslint-disable-next-line no-console
          console.warn('Cannot find visibility ' + visibility + ' for ' + ownerType)
          return null
        }
      },
      localVisibility: {
        get() {
          return this.visibility
        },
        set(value) {
          this.$emit('update:visibility', value)
        }
      },
      localTargetFeedListUid: {
        get() {
          return this.targetFeedListUid
        },
        set(value) {
          this.$emit('update:targetFeedListUid', value)
        }
      },
      options() {
        if(!this.editable || (this.publication && FeedList.get(this.publication.by).owner !== this.$auth.user.sub)) {
          return []
        }

        let options = []

        for (let feedListUid of this.feedListsUid) {
          let feedList = this.feedList(feedListUid)
          if (!this.$can('publish', feedList)) {
            continue
          }

          const owner  = this.feedListOwner(feedList)
          if(owner instanceof Person && owner.uid !== this.$auth.user.sub) {
            continue
          }

          const ownerOptions = getOptions(this.$auth.user.sub, feedList)

          if (ownerOptions) {
            options = options.concat(ownerOptions)
          }
        }

        return options
      }
    },
    created() {
      if (!this.localVisibility && this.options.length) {
        this.localVisibility = this.options[0].visibility
        this.localTargetFeedListUid = this.options[0].feedListUid
      }
    },
    methods: {
      selectOption(feedListUid, visibility) {
        this.localVisibility = visibility
        this.localTargetFeedListUid = feedListUid

        this.$emit('change')
      }
    }
  }
</script>

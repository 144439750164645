<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div id="c-wall">
    <div class="publication-container">
      <transition appear name="slide-y-transition">
        <feed-publication-form v-if="$can('publish', feedList)"
                               :allow-alert="allowAlert"
                               @submit="publish"
        />
      </transition>

      <slot name="subscriptions-filter" />
      <slot name="subscribe-options" />

      <feed-publication-pending v-for="(item, index) in pendingItems"
                                :key="index" :publication="item.publication" :feed-list-uid="item.targetFeedListUid"
      />

      <q-infinite-scroll ref="is" :disable="loadingError || feedList.endReached" @load="load">
        <transition-group name="slide-y-transition">
          <div v-for="item in items" :key="item.uid">
            <transition appear name="slide-x-transition">
              <feed-item :item="item" :feed-list="feedList" />
            </transition>
          </div>
        </transition-group>
      </q-infinite-scroll>

      <transition appear name="fade">
        <v-container v-if="loading" class="pa-0 p-rel" fluid>
          <v-card v-for="i in 2" :key="i" class="elevation-x pa-3 pa-md-3 mb-6">
            <v-skeleton-loader height="60" type="list-item-avatar-two-line" />
            <v-skeleton-loader class="mt-10" height="60" type="paragraph" />
            <v-skeleton-loader class="mt-8" max-width="500" type="sentences" />
            <v-skeleton-loader class="mt-12" type="text" />
          </v-card>
        </v-container>
      </transition>

      <div v-show="loadingError">
        <div class="text-center">
          <p>Une erreur est survenue</p>
          <v-btn color="primary" small @click="loadingError = false">Réessayer</v-btn>
        </div>
      </div>

      <template v-if="feedList.endReached && !loading">
        <div v-if="Object.keys(items).length" class="text-center font-xs py-2 op-20">Fin de liste</div>
        <v-card v-else class="py-3 px-5 elevation-x">
          <div class="d-flex align-center">
            <font-awesome-icon size="3x" class="mr-4 primary--text" :icon="['fad', 'comments']" />
            <div>
              <div class="line-height-normal text-h6 pa-0 primary--text font-weight-light">Aucune publication</div>
              <div class="text-caption grey--text">Ce fil d'actualité ne contient aucune publication</div>
            </div>
          </div>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { QInfiniteScroll } from 'quasar'
  import { FeedList } from '@/models'
  import FeedItem from '@/modules/feeds/components/Item/FeedItem'
  import FeedPublicationForm from '@/modules/feeds/components/FeedPublicationForm'
  import FeedPublicationPending from '@/modules/feeds/components/Publication/FeedPublicationPending'
  import { clone, compare } from '@/utils/array'

  export default {
    name: 'Feed',
    components: {
      FeedPublicationPending,
      FeedPublicationForm,
      FeedItem,
      QInfiniteScroll
    },
    provide() {
      return {
        eventBus: this.eventBus,
        feedListUid: this.feedListUid,
        targetFeedListsUid: this.targetFeedListsUid
      }
    },
    props: {
      feedListUid: {
        type: String,
        required: true
      },
      targetFeedListsUid: {
        type: Array,
        default() {
          return [this.feedListUid]
        }
      },
      limit: {
        type: Number,
        default: 10
      },
      allowAlert: Boolean,
      filterSubscriptionsUid: Array
    },
    data() {
      return {
        loadingError: false,
        loading: false,
        pinnedItemsFetch: false,
        eventBus: new Vue()
      }
    },
    computed: {
      items() {
        return this.feedList.listSortedItems()
      },
      pendingItems() {
        return this.$store.getters['feeds/byFeedList'](this.feedListUid)
      },
      feedList() {
        return FeedList.get(this.feedListUid)
      },
      endReached() {
        return this.feedList && this.feedList.endReached
      }
    },
    watch: {
      filterSubscriptionsUid() {
        this.reset()
      },
      feedList(value) {
        if (value && !this.items.length) {
          this.$refs.is.trigger()
        }
      },
      endReached(value) {
        if (!value) {
          this.$nextTick(() => {

            this.$refs.is.resume()
            this.$refs.is.trigger()
          })
        }
      }
    },
    mounted() {
      if (this.feedList && !this.items.length) {
        this.$refs.is.trigger()
      }
    },
    methods: {
      reset() {
        this.feedList.reset()
        this.$refs.is.reset()
        this.$refs.is.resume()
        this.$refs.is.trigger()
      },
      async load(index, done) {
        try {
          this.loading = true
          let currentSubscriptionsUid = Array.isArray(this.filterSubscriptionsUid) ? clone(this.filterSubscriptionsUid) : null

          done(await this.feedList.load(this.limit, this.filterSubscriptionsUid))

          // Reset feed if filter has changed during load, we need to refresh data using new filters
          if (Array.isArray(currentSubscriptionsUid) && !compare(currentSubscriptionsUid, this.filterSubscriptionsUid)) {
            this.$nextTick(this.reset)
          }
        } catch (e) {
          this.loadingError = true

          done(false)
          throw e
        } finally {
          this.loading = false
        }
      },
      async publish({targetFeedListUid, message, open_graph, visibility, files, alert}, done) {
        this.feedList.publish(targetFeedListUid, this.$auth.user.sub, message, open_graph, visibility, files, alert)
        done(true)
      }
    }
  }
</script>

<template>
  <feed-publication-container v-if="publication && sharedPublication" :loading="loading > 0" :item="item"
                              :publication="sharedPublication" :feed-list="feedList" :comments-section="commentsSection"
                              @update="updatingPublication = true" @share="sharingPublication = true"
  >
    <feed-publication :feed-list="feedList" :pinned="item.pinned" :publication="publication" disable-visibility-change>
      <template v-slot:actions>
        <feed-publication-sharing-actions :feed-list="feedList" :item="item" :publication="publication" :shared-publication="sharedPublication"
                                          @loading="++loading" @loaded="--loading"
                                          @update="updatingPublication = true"
                                          @share="sharingPublication = true"
        />
      </template>
    </feed-publication>
    <div style="border: 1px solid #ddd; border-radius: 5px;" class="mx-3">
      <feed-publication :feed-list="feedList" :publication="sharedPublication" />
    </div>
    <feed-publication-share-dialog v-if="sharingPublication"
                                   :shared-publication="sharedPublication"
                                   @close="sharingPublication = false"
    />
    <feed-publication-update-dialog v-if="updatingPublication"
                                    :publication="publication"
                                    @close="updatingPublication = false"
    />

    <template #publication-actions>
      <v-btn v-if="$can('share', sharedPublication)" text x-small dense class="primary--text" @click="sharingPublication = true">
        <font-awesome-icon :icon="['fad', 'share']" size="lg" class="primary--text mr-2" />
        Partager
      </v-btn>
      <v-btn v-if="$can('update', publication)" text x-small dense class="primary--text" @click="updatingPublication = true">
        <font-awesome-icon :icon="['fad', 'pencil']" size="1x" class="primary--text mr-2" />
        Editer
      </v-btn>
    </template>
  </feed-publication-container>
</template>

<script>
  import FeedPublicationShareDialog from '@/modules/feeds/components/Publication/Share/FeedPublicationShareDialog'
  import FeedPublicationUpdateDialog from '@/modules/feeds/components/Publication/FeedPublicationUpdateDialog'
  import { CommentsSection, FeedItemPublicationSharing, FeedList, Publication } from '@/models'
  import {FeedListOwner} from '@/modules/feeds/models/FeedListOwner'
  import FeedPublication from '@/modules/feeds/components/Publication/FeedPublication'
  import FeedPublicationContainer from '@/modules/feeds/components/Publication/FeedPublicationContainer'
  import FeedPublicationSharingActions from '@/modules/feeds/components/Publication/FeedPublicationSharingActions'

  export default {
    name: 'FeedItemPublicationSharing',
    components: {
      FeedPublicationSharingActions,
      FeedPublicationContainer,
      FeedPublication,
      FeedPublicationShareDialog,
      FeedPublicationUpdateDialog
    },
    props: {
      feedList: FeedList,
      item: FeedItemPublicationSharing
    },
    data() {
      return {
        loading: 0,
        sharingPublication: null,
        updatingPublication: null
      }
    },
    computed: {
      listOwner() {
        return FeedListOwner.get(this.feedList.owner)
      },
      onOwnerVisible() { // On ne voit le context de la publication que si  le on actuel est différent ou a défaut la liste est différente (owner)
        return this.onOwner && this.listOwner !== this.onOwner
      },
      publication() {
        return Publication.get(this.item.publication)
      },
      sharedPublication() {
        return Publication.get(this.item.shared_publication)
      },
      commentsSection() {
        return CommentsSection.get(this.publication.comments_section)
      }
    }
  }
</script>

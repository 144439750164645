<template>
  <div class="py-md-3 px-md-5 py-2 px-3">
    <feed-publication-header :list="feedList" :publication="publication" :pinned="pinned" :disable-visibility-change="disableVisibilityChange">
      <template v-slot:actions>
        <slot name="actions" />
      </template>
    </feed-publication-header>
    <feed-publication-body :list="feedList" :publication="publication" />
  </div>
</template>

<script>
  import FeedPublicationHeader from '@/modules/feeds/components/Publication/FeedPublicationHeader'
  import FeedPublicationBody from '@/modules/feeds/components/Publication/FeedPublicationBody'
  import {FeedList, Publication} from '@/models'

  export default {
    name: 'FeedPublication',
    components: {FeedPublicationBody, FeedPublicationHeader},
    props: {
      feedList: FeedList,
      publication: Publication,
      pinned: Boolean,
      disableVisibilityChange: Boolean
    }
  }
</script>

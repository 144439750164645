<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div v-if="files && files.length > 0">
    <v-list-item v-for="file in files" :key="file.uid" two-line class="mb-2 post-file" @click="viewDocument(file.uid)">
      <v-list-item-icon size="45" class="my-2">
        <file-mime-type-preview :size="45" :mime-type="file.mime_type" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-2">{{ file.name }}</v-list-item-title>
        <v-list-item-subtitle class="text-caption">{{ file.size | prettyBytes }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
  import FileMimeTypePreview from '@/modules/files/components/FileMimeTypePreview'
  import {File} from '@/models'

  export default {
    name: 'FeedMediaSection',
    components: {FileMimeTypePreview},
    props: {
      filesUid: Array,
      default: []
    },
    computed:{
      files() {
        return this.filesUid ? this.filesUid.map(uid => File.get(uid)) : []
      }
    },
    methods: {
      viewDocument(uid) {
        this.$root.$emit('fileviewer:open', { uid: uid })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .post-file {
    border: 1px solid rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }
</style>

<template>
  <div v-show="uploads.length" class="publication-files medias d-flex overflow-x-auto overflow-y-hidden">
    <v-card v-for="(upload, index) in uploads" :key="index"
            width="80" outlined class="mr-2 text-center pa-1"
            :class="{ 'animate-flicker' : !isUploadDone(index) }"
    >
      <v-sheet height="45" class="p-relative">
        <transition name="fade" mode="out-in">
          <v-progress-circular v-if="!isUploadDone(index)" :key="'progress-'+index" size="45" color="primary" class="text-caption mx-auto"
                               :value="upload.progress"
                               :indeterminate="upload.progress === 100"
          >
            {{ upload.progress }}%
          </v-progress-circular>
          <file-mime-type-preview v-else :key="'mime-preview-'+index" :size="45" :mime-type="upload.file.fileType" class="mx-auto" />
        </transition>
      </v-sheet>
      <div class="file-name">{{ upload.file.name }}</div>
      <div class="file-size">{{ upload.file.size | prettyBytes }}</div>
      <button type="button" class="remove-document p-abs rounded"
              @click="cancelUpload(index)"
      >
        <v-icon small>$close</v-icon>
      </button>
      <transition name="slide-fade">
        <div v-show="isUploadDone(index)" style="position: absolute; bottom: -8px; right: -8px;">
          <font-awesome-layers class="fa-lg">
            <font-awesome-icon icon="circle" class="success--text" />
            <font-awesome-icon icon="check" transform="shrink-6" class="white--text" />
          </font-awesome-layers>
        </div>
      </transition>
    </v-card>
    <file-input ref="input-file" hidden @upload="onFilesInput" />
  </div>
</template>

<script>
  import FileMimeTypePreview from '@/modules/files/components/FileMimeTypePreview'
  import {column, removeItem} from '@/utils/array'
  import {File, FileList, Space} from '@/models'
  import {UPLOADING_STATUS} from '@/modules/files/store/files-upload'
  import {postAndUpload} from '@/modules/files/api'
  import FileInput from '@/modules/files/components/upload/FileInput'

  const MAX_UPLOADS_LIMIT = 255

  export default {
    name: 'FilesUploadRow',
    components: {FileInput, FileMimeTypePreview},
    data() {
      return {
        uploads: []
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      spaceFileList() {
        return FileList.get(this.space.fileList)
      },

      userFileList() {
        return FileList.get(this.$auth.user.sub)
      },
      isUploadDone() {
        return index => {
          return this.uploads[index].status === UPLOADING_STATUS.done
        }
      },
      uploadedFilesUid() {
        return column(this.uploads.filter(upload => upload.uid), 'uid')
      },
      isUploading() {
        return this.uploads.find(upload => upload.status !== UPLOADING_STATUS.done)
      }
    },
    watch: {
      uploadedFilesUid(value) {
        this.$emit('update', value)
      },
      isUploading(value) {
        this.$emit(value ? 'uploading' : 'uploaded')
      }
    },
    created() {
      this.space.fetchFileList()

      FileList.create(this.$auth.user.sub)
    },
    methods: {
      clear() {
        this.uploads = []
      },
      openFilesSelector() {
        this.$refs['input-file'].click()
      },
      cancelUpload(index) {
        this.uploads.splice(index, 1)
      },
      removeMedia(media) {
        this.medias = removeItem(this.medias, media)
      },
      onFilesInput(files) {
        for (let file of files) {
          if(this.uploads.length >= MAX_UPLOADS_LIMIT) {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Maximum de fichiers atteint',
              subtitle: 'Le maximum de fichiers a été atteint.'
            })
            break
          }

          let fileUpload = {
            uid: null,
            file,
            progress: 0,
            status: UPLOADING_STATUS.pending
          }
          this.uploads.push(fileUpload)


          postAndUpload(file, {
            ...File.model,
            on: this.spaceFileList.uid
          }, progress => {
            this.$set(fileUpload, 'status', UPLOADING_STATUS.processing)
            this.$set(fileUpload, 'progress', progress)
          }).then((file) => {
            this.$set(fileUpload, 'status', UPLOADING_STATUS.done)
            this.$set(fileUpload, 'progress', 100)
            this.$set(fileUpload, 'uid', file.uid)
            File.save(file)
            this.$emit('upload', file.uid)
          }).catch(e => {
            this.$set(fileUpload, 'status', UPLOADING_STATUS.pending)
            this.$set(fileUpload, 'progress', 0)
            this.$handleApiError(e)
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .medias {
    .remove-document {
      min-width: 24px;
      top: -11px;
      right: -11px;
    }
    .percent {
      font-size: 0.5rem;
    }
  }
  .publication-files {
    .file-name {
      font-size: 0.7em;
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .file-size {
      font-size: 0.5em;
      color: #888;
    }
  }
</style>

<template>
  <form @submit.prevent="submit">
    <form-category title="Partager" subtitle="Partager cette publication" />
    <div class="form-group content pt-0">
      <v-textarea v-model="message" auto-grow
                  clearable placeholder="Exprimez-vous"
                  maxlength="10000"
                  lang="fr"
                  spellcheck="true"
                  class="textarea pt-1"
                  :loading="submitting"
                  :disabled="submitting"
                  @change="onChange"
      />
      <user-avatar :user="$auth.user" class="rounded picture" size="35" />
    </div>

    <feed-publication-shared :publication="sharedPublication" />

    <div class="text-center mt-3">
      <feed-publication-visibility editable button-style
                                   :feed-lists-uid="[feedList.uid]"
                                   :target-feed-list-uid="feedList.uid"
                                   :visibility.sync="visibility"
      />
      <v-btn small color="primary" type="submit" :loading="submitting" :disabled="submitting">
        Publier
      </v-btn>
    </div>
  </form>
</template>

<script>
  import {empty} from 'esyn-utils/src/utils/variables'
  import { FeedList, FeedListOwner, Person, Publication } from '@/models'
  import FeedPublicationShared from '@/modules/feeds/components/Publication/Share/FeedPublicationShared'
  import {sharePublication} from '@/modules/feeds/api'
  import UserAvatar from '@/modules/core/layout/UserAvatar'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import FeedPublicationVisibility from '@/modules/feeds/components/Publication/FeedPublicationVisibility'

  export default {
    name: 'FeedPublicationShareForm',
    components: {FeedPublicationVisibility, UserAvatar, FeedPublicationShared, FormCategory},
    inject: ['targetFeedListsUid'],
    props: {
      feedList: FeedList,
      sharedPublication: Publication,
      audience: String
    },
    data() {
      return {
        message: '',
        submitting: false,
        confirmLeaveIndex: null,
        visibility: this.value ? this.value.visibility : (FeedListOwner.get(this.feedList.owner) instanceof Person ? 'private' : 'members'),
        targetFeedListUid: this.targetFeedListsUid[0] || null
      }
    },
    computed: {
      ownerIcon() {
        let icon
        switch (this.owner.type) {
          case 'user':
          case 'user:patient':
          case 'user:professional':
            icon = 'mdi-account'
            break
          default:
            icon = 'mdi-account-group'
            break
        }
        return icon
      },
      owner() {
        return FeedListOwner.get(this.feedList.owner)
      }
    },
    beforeDestroy() {
      this.clearConfirmLeave()
    },
    methods: {
      clearConfirmLeave() {
        if (this.confirmLeaveIndex !== null) {
          this.$clearConfirmLeave(this.confirmLeaveIndex)
          this.confirmLeaveIndex = null
        }
      },
      onChange() {
        if(empty(this.message)) {
          this.clearConfirmLeave()
        }
        else if(this.confirmLeaveIndex === null) {
          this.confirmLeaveIndex = this.$confirmLeave('Vous n’avez pas encore fini votre publication. Voulez-vous quitter sans la terminer ?')
        }
      },
      async submit() {
        try {
          this.submitting = true

          await sharePublication(this.sharedPublication.uid, this.feedList.uid, this.message)
          this.message = ''

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Publication partagée',
            subtitle: 'La publication a été partagée avec succès.'
          })
          this.$clearConfirmLeave()
          this.$emit('submitted')
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>

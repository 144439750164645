<template>
  <v-list-item-action v-if="actions && actions.length">
    <app-actions-adaptive :actions="actions">
      <template slot-scope="{ on, attrs}">
        <v-btn depressed x-small fab color="transparent" v-bind="attrs" :loading="loading" v-on="on">
          <font-awesome-icon fixed-width size="2x" :icon="['far', 'ellipsis-v']" class="op-50" />
        </v-btn>
      </template>
    </app-actions-adaptive>
  </v-list-item-action>
</template>

<script>
  import AppActionsAdaptive from '@/modules/core/layout/app/AppActionsAdaptive'

  export default {
    name: 'AppActions',
    components: {AppActionsAdaptive},
    props: {
      actions: {
        type: Array,
        default() {
          return []
        }
      },
      loading: Boolean
    }
  }
</script>

<template>
  <div class="body publication">
    <div class="content text-display">
      <span v-if="publication.isModified()" class="font-alt text-caption font-weight-light" :title="updateDate">(Modifié)</span>
      <div class="w-100">
        {{ publication.message }}
      </div>
    </div>

    <feed-media-section :files-uid="publication.files" />
    <open-graph :data="publication.open_graph" />
  </div>
</template>

<script>
  import OpenGraph from '@/modules/feeds/components/FeedOpenGraph'
  import FeedMediaSection from '@/modules/feeds/components/FeedMediaSection'
  import {FeedList, Publication} from '@/models'

  export default {
    name: 'FeedPublicationBody',
    components: {
      FeedMediaSection,
      OpenGraph
    },
    inject: ['eventBus'],
    props: {
      feedList: FeedList,
      publication: Publication
    },
    data() {
      return {
        modificationsVisible: false,
        modificationsLoaded: false,
        updating: false
      }
    },
    computed: {
      updateDate(){
        return this.$dayjs.utc(this.publication.update_date).local().format('LLL')
      }
    },
    mounted() {
      if (this.publicationUid) {
        this.eventBus.$on('items:' + this.publication.uid + ':update', this.onUpdate)
      }
    },
    beforeDestroy() {
      if (this.publicationUid) {
        this.eventBus.$off('items:' + this.publication.uid + ':update', this.onUpdate)
      }
    },
    methods: {
      onUpdate() {
        this.updating = true
      }
    }
  }
</script>

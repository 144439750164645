<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <ul>
    <li>{{ popData.total_members }} membres</li>
    <li>
      Statut : <span :class="popData.status === 'closed' ? 'gray--text' : 'success--text'">
        {{ popData.status === 'closed' ? 'Cloturé' : 'Actif' }}</span>
    </li>
    <template v-if="popData.patient">
      <li>Groupe orienté patient</li>
      <li>
        Patient : <span class="font-weight-medium secondary--text text--darken-1">
          {{ popData.patient.full_name }}</span>
      </li>
    </template>
    <li v-else>Groupe de discussion libre</li>
    <li v-if="membershipData">
      Membre depuis le <span class="font-weight-bold">
        {{ $dayjs(membershipData.creation_date.birth_date).format('D MMMM YYYY') }}
      </span>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'TeamData',
    props: {
      popData: Object,
      membershipData: Object
    }
  }
</script>

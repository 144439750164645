/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {mapState, mapMutations} from 'vuex'

export default {
  computed: {
    ...mapState('searchBar', ['terms'])
  },

  methods: {
    ...mapMutations('searchBar', {
      setSearchBarLoading: 'setLoading'
    })
  }

}

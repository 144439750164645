<template>
  <v-card class="elevation-x pa-3">
    <app-author :user="byOwner" :date="publication.creation_date" />

    <div class="text-display">
      <div class="w-100">
        {{ publication.message }}
      </div>
    </div>

    <feed-media-section :files-uid="publication.files" />
    <feed-open-graph :data="publication.open_graph" />
  </v-card>
</template>

<script>
  import FeedMediaSection from '@/modules/feeds/components/FeedMediaSection'
  import FeedOpenGraph from '@/modules/feeds/components/FeedOpenGraph'
  import {FeedList, FeedListOwner, Publication} from '@/models'
  import AppAuthor from '@/modules/core/layout/app/AppAuthor'

  export default {
    name: 'FeedPublicationShared',
    components: {AppAuthor, FeedOpenGraph, FeedMediaSection},
    props: {
      publication: Publication
    },
    computed: {
      by() {
        return FeedList.get(this.publication.by)
      },
      byOwner() {
        return this.by ? FeedListOwner.get(this.by.owner) : null
      }
    }
  }
</script>

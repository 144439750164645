<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <ul>
    <li>Métier : <span class="font-weight-medium secondary--text text--darken-1">{{ getJob(popData.job) }}</span></li>
    <li>
      Spécialité : <span class="font-weight-medium">
        <pro-specialties-displayer text :specialties="popData.specialties" :max="2" class="d-inline" />
      </span>
    </li>
    <li>
      Localité : <span class="font-weight-medium">
        <address-displayer :address="getAddress(popData.main_address)" city-only class="d-inline" /></span>
    </li>
  </ul>
</template>

<script>
  import conditionalData from '@/mixins/conditionalData'
  import ProSpecialtiesDisplayer from '@/modules/core/layout/ProSpecialtiesDisplayer'
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'

  export default {
    name: 'ProfessionalData',
    components: {AddressDisplayer, ProSpecialtiesDisplayer},
    mixins: [conditionalData],
    props: {
      popData: Object
    }
  }
</script>

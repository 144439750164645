import { FeedListOwner, Person, Space } from '@/models'

const personVisibilities = {
  public: {
    icon: ['fad', 'id-card-alt'],
    title: 'Ma page',
    subtitle: 'Visible publiquement sur mon profil'
  },
  private: {
    icon: ['fad', 'lock-alt'],
    title: 'Privé',
    subtitle: 'Visible par moi uniquement'
  }
}
const visibilities = {
  space: {
    members: {
      icon: ['fad', 'globe-europe'],
      title: 'Tous les membres de l\'espace',
      subtitle: 'Visible par tous les membres de l\'espace'
    },
    professional_members: {
      icon: ['fad', 'globe-europe'],
      title: 'Professionnels de l\'espace',
      subtitle: 'Visible par les professionnels de l\'espace'
    },
    patient_members: {
      icon: ['fad', 'globe-europe'],
      title: 'Patients de l\'espace',
      subtitle: 'Visible par les patients de l\'espace'
    }
  },
  team: {
    members: {
      icon: ['fad', 'globe-europe'],
      title: 'Membres du groupe',
      subtitle: 'Visible par les membres du groupe'
    }
  },
  user: personVisibilities,
  'user:patient': personVisibilities,
  'user:professional': {
    ...personVisibilities,
    contacts: {
      icon: ['fad', 'address-book'],
      title: 'Mes contacts',
      subtitle: 'Visible par mes contacts'
    }
  }
}

export default visibilities

function visibilitiesToOption(feedListUid, visibilities) {
  let options = []
  for (let visibility in visibilities) {
    options.push({
      feedListUid,
      visibility,
      ...visibilities[visibility]
    })
  }
  return options
}

export function getOptions(currentUserUid, feedList) {
  const owner = FeedListOwner.get(feedList.owner)
  let ownerVisibilities = null
  if (owner instanceof Space) {
    ownerVisibilities = owner.has_patients ? visibilities.space : {
      professional_members: {
        ...visibilities.space.professional_members,
        subtitle: 'Visible par tous les membres'
      }
    }
  } else if (owner instanceof Person) {
    if (owner.uid === currentUserUid) {
      ownerVisibilities = visibilities[owner.type]
    }
  } else {
    ownerVisibilities = visibilities[owner.type]
  }
  return ownerVisibilities ? visibilitiesToOption(feedList.uid, ownerVisibilities) : null
}

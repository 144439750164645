<template>
  <v-list :disabled="loading || disabled">
    <v-list-item v-if="title">
      <v-list-item-title class="font-weight-bold">{{ title }}</v-list-item-title>
    </v-list-item>

    <template v-for="(item, index) in actions">
      <v-list-item :key="index" @click="select(index)">
        <v-list-item-avatar v-if="item.icon" class="mr-1">
          <font-awesome-icon fixed-width size="sm" :icon="['fad', item.icon]" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.label }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>


        <component :is="item.component" v-if="component === index" v-bind="item.props" />
        <app-actions-list v-if="nestedActions === index" :actions="item.actions" />
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
  import {mapState} from 'vuex'

  /**
   * @internal Une liste d'actions qui devrait être utilisé à partir de AppActionsAdaptive
   */
  export default {
    name: 'AppActionsList',
    props: {
      title: {type: String, default: 'Actions'},
      // Format: [{label: String, action: Function}},
      // l'action peut renvoyer une promesse pour afficher une barre de chargement
      actions: Array
    },
    data() {
      return {
        value: false,
        loading: false,
        disabled: false,
        component: null,
        nestedActions: null
      }
    },
    computed: {
      ...mapState('layout', ['isMobile'])
    },
    methods: {
      select(index) {
        const item = this.actions[index]

        if (item.action) {
          this.execute(item.action)
        } else if (item.actions) {
          this.nestedActions = index
          this.disabled = true
        } else if (item.component) {
          this.component = index
          this.disabled = true
        }
      },
      execute(callback) {
        if (typeof callback !== 'function') {
          throw new Error('Callback must be of type function, got ' + typeof callback)
        }
        callback()

        // https://stackoverflow.com/a/27760489/4864628
        // Checking if something is promise unnecessarily complicates the code, just use Promise.resolve
        //   await Promise.resolve(callback())
        //   this.$emit('select')
      }
    }
  }
</script>

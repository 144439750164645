<template>
  <v-list-item class="px-0">
    <v-list-item-avatar class="mr-3 mt-3 align-self-start">
      <user-avatar-img :user="user" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center flex-wrap">
        <router-link class="font-weight-medium"
                     :to="{name: user.type === 'patient' ? 'patient-profile' : 'professional-profile', params: {uid: user.uid} }"
        >
          <entity-pop-over class="op-80" :entity="user" />
        </router-link>
        <slot name="right" class="ml-1" />
      </v-list-item-title>
      <v-list-item-subtitle v-if="date">
        <slot name="subtitle">
          <base-date :date="date" class="mr-1" />
          <slot name="sub-right" />
        </slot>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import BaseDate from '@/modules/core/components/BaseDate'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'
  import EntityPopOver from '@/modules/core/layout/popover/EntityPopOver'

  export default {
    name: 'AppAuthor',
    components: {EntityPopOver, UserAvatarImg, BaseDate},
    props: {
      user: {
        type: Object,
        required: true
      },
      date: {
        type: String
      }
    }
  }
</script>

<template>
  <component :is="component" v-if="component" :feed-list="feedList" :item="item" />
</template>

<script>
  import {FeedItem, FeedList} from '@/models'
  import FeedItemPublication from '@/modules/feeds/components/Item/FeedItemPublication'
  import FeedItemPublicationSharing from '@/modules/feeds/components/Item/FeedItemPublicationSharing'

  const COMPONENT_BY_TYPE = {
    publication: FeedItemPublication,
    publication_sharing: FeedItemPublicationSharing
  }

  export default {
    name: 'FeedItem',
    props: {
      item: FeedItem,
      feedList: FeedList
    },
    computed: {
      component() {
        return COMPONENT_BY_TYPE[this.item.type]
      }
    },
    methods: {
      onFocus() {
        // this.$vuetify.goTo(this)
      }
    }

  }
</script>

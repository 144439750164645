<template>
  <feed-publication-container v-if="publication" :publication="publication" :item="item" :loading="loading > 0"
                              :feed-list="feedList" :comments-section="commentsSection"
                              @update="updatePublication" @share="sharingPublication = true"
  >
    <feed-publication :feed-list="feedList" :pinned="item.pinned" :publication="publication">
      <template v-slot:actions>
        <feed-publication-actions ref="actions" :feed-list="feedList" :item="item" :publication="publication"
                                  @loading="++loading" @loaded="--loading"
                                  @update="updatePublication"
                                  @share="sharingPublication = true"
        />
      </template>
    </feed-publication>

    <feed-publication-share-dialog v-if="sharingPublication"
                                   :shared-publication="publication"
                                   @close="sharingPublication = false"
    />
    <feed-publication-update-dialog v-if="updatingPublication"
                                    :publication="publication"
                                    @close="updatingPublication = null"
                                    @update="onUpdate"
    />

    <template #publication-actions>
      <v-btn v-if="$can('share', publication)" text x-small dense class="primary--text" @click="sharingPublication = true">
        <font-awesome-icon :icon="['fad', 'share']" size="lg" class="primary--text mr-2" />
        Partager
      </v-btn>
      <v-btn v-if="$can('update', publication)" text x-small dense class="primary--text" @click="updatePublication">
        <font-awesome-icon :icon="['fad', 'pencil']" size="1x" class="primary--text mr-2" />
        Editer
      </v-btn>
    </template>
  </feed-publication-container>
</template>

<script>
  import FeedPublicationShareDialog from '@/modules/feeds/components/Publication/Share/FeedPublicationShareDialog'
  import FeedPublicationUpdateDialog from '@/modules/feeds/components/Publication/FeedPublicationUpdateDialog'
  import FeedPublicationContainer from '@/modules/feeds/components/Publication/FeedPublicationContainer'
  import { CommentsSection, FeedItemPublication, FeedList, FeedListOwner, Publication } from '@/models'
  import FeedPublication from '@/modules/feeds/components/Publication/FeedPublication'
  import FeedPublicationActions from '@/modules/feeds/components/Publication/FeedPublicationActions'

  export default {
    name: 'FeedItemPublication',
    components: {
      FeedPublicationActions,
      FeedPublicationContainer,
      FeedPublication,
      FeedPublicationShareDialog,
      FeedPublicationUpdateDialog
    },
    props: {
      feedList: FeedList,
      item: FeedItemPublication
    },
    data() {
      return {
        loading: 0,
        sharingPublication: false,
        updatingPublication: false,
        previousData: null
      }
    },
    computed: {
      publication() {
        return Publication.get(this.item.publication)
      },
      commentsSection() {
        return CommentsSection.get(this.publication.comments_section)
      },
      onLabel() {
        let label = ''
        let owner = FeedListOwner.get(FeedList.get(this.publication.on).owner)
        switch (owner.type) {
          case 'space':
            label += ' de l\'espace '
            break
          case 'establishment':
            label += ' de l\'établissement '
            break
          case 'team':
            label += ' du groupe '
            break
          default:
            label += ' de '
            break
        }
        label += owner.name
        return label
      }
    },
    methods: {
      updatePublication() {
        this.updatingPublication = true
        this.previousData = {
          pinned: this.item.pinned,
          feedListUid: this.publication.on
        }
      },
      async onUpdate(publication) {
        if (this.previousData.pinned && this.previousData.feedListUid !== publication.on) {
          this.item.$mutate(item => {
            item.pinned = false
          })

          if (await this.$confirm('La publication a été désépinglé. Souhaitez vous la réépingler sur le fil d\'actualité ' + this.onLabel + ' ?')) {
            await this.$refs.actions.pin()
          }
        }
      }
    }
  }
</script>

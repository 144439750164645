import { render, staticRenderFns } from "./FeedPublicationForm.vue?vue&type=template&id=5e6dd004&scoped=true&"
import script from "./FeedPublicationForm.vue?vue&type=script&lang=js&"
export * from "./FeedPublicationForm.vue?vue&type=script&lang=js&"
import style0 from "./FeedPublicationForm.vue?vue&type=style&index=0&id=5e6dd004&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e6dd004",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCol,VDivider,VProgressLinear,VRow,VSpacer,VTextarea})

<template>
  <empty-list v-if="!loading && visibleItems.length === 0 " subtitle="Vous n'avez aucun contact dans votre liste"
              title="Aucun contact" class="empty-height-auto"
  />
  <v-card v-else class="elevation-x is-scroll-target">
    <v-list class="pa-0 " three-line>
      <q-infinite-scroll ref="is" scroll-target=".is-scroll-target" @load="nextPage">
        <template v-for="(uid, index) in visibleItems">
          <user-list-item :key="uid" :user="user(uid)" :show-buttons="!selectable" @click="select(uid)" />
          <v-divider v-if="index + 1 < visibleItems.length" :key="'divider-'+index" class="my-0" />
        </template>
        <template v-slot:loading>
          <skeleton-list :small="visibleItems.length > 0" />
        </template>
      </q-infinite-scroll>
    </v-list>
  </v-card>
</template>

<script>
  import UserListItem from '@/modules/core/layout/UserListItem'
  import SkeletonList from '@/modules/core/layout/SkeletonList'
  import EmptyList from '@/modules/core/layout/EmptyList'
  import searchBar from '@/mixins/searchBar'
  import {mapState} from 'vuex'
  import {QInfiniteScroll} from 'quasar'
  import {Person, Space} from '@/models'

  export default {
    name: 'UserContactsList',
    components: {EmptyList, SkeletonList, UserListItem, QInfiniteScroll},
    mixins: [searchBar],
    props: {
      selectable: Boolean,
      hideSelected: Boolean,
      selectMultiple: Boolean,
      selected: {
        type: null,
        default: () => {
          return this.multiple ? [] : null
        }
      },
      filterBySpace: Boolean,
      filtered: Array
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState('contacts', ['items', 'page', 'search', 'spaceFiltered']),
      space() {
        return Space.get(this.$store.state.core.space)
      },
      visibleItems() {
        let items = this.items.filter(item => {
          return !this.selectable || !this.hideSelected || !this.isSelected(item)
        })
        if(this.filtered) {
          items = items.filter(item => this.filtered.indexOf(item) === -1)
        }
        return items
      },
      localSelected: {
        get() {
          return this.selected
        },
        set(value) {
          this.$emit('update:selected', value)
        }
      },
      isSelected() {
        return item => {
          return this.selectable && (this.selectMultiple ? this.localSelected.indexOf(item) !== -1 : this.localSelected === item)
        }
      },
      user() {
        return uid => Person.get(uid)
      }
    },
    watch: {
      spaceFiltered() {
        this.reset()
      },
      search() {
        this.reset()
      }
    },
    created() {
      if (this.items.length) {
        this.loading = false
      }
    },
    methods: {
      reset() {
        this.loading = true
        this.$store.commit('contacts/reset')
        this.$nextTick(() => {
          this.$refs.is.reset()
          this.$refs.is.resume()
        })
      },
      async nextPage(index, done) {
        this.loading = true
        done(await this.$store.dispatch('contacts/fetchPage'))
        this.loading = false
      },
      select(item) {
        if (!this.isSelected(item)) {
          if (this.selectMultiple) {
            this.localSelected.push(item)
          } else {
            this.localSelected = item
          }
        }
      }
    }
  }
</script>


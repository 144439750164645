<template>
  <div>
    <v-bottom-sheet v-if="isMobile" v-model="value" :disabled="!actions || !actions.length">
      <template v-slot:activator="props">
        <slot v-bind="props" />
      </template>
      <app-actions-list :title="title" :actions="actions" @select="value=false" />
    </v-bottom-sheet>
    <v-menu v-else v-model="value" offset-x nudge-bottom="20" :close-on-content-click="false" :disabled="!actions || !actions.length"
            :max-width="$vuetify.breakpoint.mdAndDown ? '300' : '500'"
    >
      <template v-slot:activator="props">
        <slot v-bind="props" />
      </template>
      <app-actions-list :title="title" :actions="actions" @select="value=false" />
    </v-menu>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import AppActionsList from '@/modules/core/layout/app/AppActionsList'

  /**
   * Une liste d'actions dans un menu sur Desktop ou en bas de page sur Mobile
   *
   */
  export default {
    name: 'AppActionsAdaptive',
    components: {AppActionsList},
    props: {
      title: {type: String, default: 'Actions'},
      // Format: [{label: String, action: Function}},
      // l'action peut renvoyer une promesse pour afficher une barre de chargement
      actions: Array
    },
    data() {
      return {
        value: false
      }
    },
    computed: {
      ...mapState('layout', ['isMobile'])
    },
    mounted() {
      if(!this.$slots.default && !this.$scopedSlots.default) {
        this.value = true
      }
    }
  }
</script>

<style scoped>

</style>

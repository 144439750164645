<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <form class="pa-3 form-reply" @submit.prevent="send">
    <v-text-field
      ref="input"
      v-model="localValue"
      filled
      rounded
      dense
      :hide-details="!error"
      :loading="busy"
      :disabled="busy"
      clearable
      placeholder="Ecrire un commentaire"
      @click:append="send"
    >
      <template v-slot:append>
        <font-awesome-icon size="lg" class="primary--text c-pointer ml-2" :icon="['fad', 'comment-alt-dots']" @click.stop="send" />
      </template>
    </v-text-field>
    <v-row v-if="error" class="error--text" justify="space-between">
      <v-col cols="auto">
        Une erreur est survenue
      </v-col>
      <v-col cols="auto">
        <v-btn color="white" small @click="send">Réessayer</v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
  import {empty} from '@/utils/variables'

  export default {
    name: 'CommentReply',
    props: {
      focusOnLoad: {
        type: Boolean
      },
      mention: {
        type: Object
      },
      onReply: {
        type: Function,
        required: true
      },
      value: {type: String, default: ''}
    },
    data: function () {
      return {
        localValue: this.value,
        busy: false,
        error: false
      }
    },
    computed: {},
    mounted() {
      if (this.focusOnLoad) {
        this.focus(this.mention)
      }
    },
    methods: {
      focus(mentionedUser) {
        if (empty(this.value) && typeof mentionedUser === 'object' && mentionedUser !== null && mentionedUser.uid !== this.$auth.user.sub) {
          this.localValue = '@' + mentionedUser.full_name + ' '
        }

        this.$refs.input.focus()
      },
      async send() {
        if (this.busy || !this.localValue.trim()) {
          return
        }

        this.error = false
        this.busy = true

        try {
          await this.onReply(this.localValue)

          this.localValue = null
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.error = true
        } finally {
          this.busy = false
        }

      }
    }

  }
</script>

<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-img src="@/assets/img/popover-bg.png" height="62px" :class="popTypes[type].color+' lighten-1 '">
    <v-list-item class="py-0 my-0">
      <v-list-item-avatar rounded :color="popTypes[type].color+' lighten-2'" size="42" class="elevation-5">
        <font-awesome-icon size="2x" class="white--text" :icon="['fad', popTypes[type].icon]" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="white--text font-weight-bold">{{ popData.name }}</v-list-item-title>
        <v-list-item-subtitle class="white--text font-weight-medium">{{ popTypes[type].name }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-img>
</template>

<script>
  export default {
    name: 'EntityPopOverHeader',
    props: {
      type: String,
      popData: Object
    },
    data() {
      return {
        popTypes: {
          team: {
            icon: 'users',
            name: 'Groupe',
            color: 'blue-grey'
          },
          space: {
            icon: 'layer-group',
            name: 'Espace',
            color: 'teal'
          },
          professional: {
            icon: 'user-md',
            name: 'Professionel de santé',
            color: 'light-green'
          },
          patient: {
            icon: 'user',
            name: 'Patient',
            color: 'deep-orange'
          }
        }
      }
    }
  }
</script>

<template>
  <v-list-item class="px-0">
    <app-author :user="byOwner" :date="publication.creation_date">
      <template v-slot:right>
        <template v-if="onOwnerVisible">
          <font-awesome-icon size="xs" class="mx-2 primary--text text--lighten-3" :icon="['fal', 'chevron-right']" />
          <entity-pop-over class="op-80" :entity="onOwner" />
        </template>
        <v-chip v-if="pinned" x-small color="secondary" class="ml-2">Epinglé</v-chip>
      </template>

      <template v-slot:sub-right>
        <feed-publication-visibility
          v-if="visibilityVisible"
          :editable="!disableVisibilityChange"
          :publication="publication"
          :loading="visibilityLoading"
          :feed-lists-uid="targetFeedListsUid"
          :visibility.sync="visibility"
          :target-feed-list-uid.sync="targetFeedListUid"
          @change="updateVisibility"
        />
      </template>
    </app-author>

    <slot name="actions" />
  </v-list-item>
</template>

<script>
  import AppAuthor from '@/modules/core/layout/app/AppAuthor'
  import * as Feeds from '@/modules/feeds/api'
  import { FeedItem, FeedList, FeedListOwner, Person, Publication } from '@/models'
  import FeedPublicationVisibility from '@/modules/feeds/components/Publication/FeedPublicationVisibility'
  import EntityPopOver from '@/modules/core/layout/popover/EntityPopOver'

  export default {
    name: 'FeedPublicationHeader',
    components: {
      EntityPopOver,
      FeedPublicationVisibility,
      AppAuthor
    },
    inject: ['targetFeedListsUid'],
    props: {
      list: FeedList,
      item: FeedItem,
      pinned: Boolean,
      publication: Publication,
      disableVisibilityChange: Boolean
    },
    data() {
      return {
        visibilityLoading: false,
        visibility: this.publication.visibility,
        targetFeedListUid: this.publication.on
      }
    },
    computed: {
      on() {
        return this.publication.on ? FeedList.get(this.publication.on) : null
      },
      onOwner() {
        return this.on ? FeedListOwner.get(this.on.owner) : null
      },
      by() {
        return FeedList.get(this.publication.by)
      },
      byOwner() {
        return FeedListOwner.get(this.by.owner)
      },
      listOwner() {
        return FeedListOwner.get(this.list.owner)
      },
      onOwnerVisible() { // On ne voit le context de la publication que si le on actuel est différent ou a défaut la liste est différente (owner)
        return this.onOwner && this.listOwner !== this.onOwner && this.onOwner !== this.byOwner
      },
      visibilityVisible() {
        // On ne voit pas la visibilité des publications poster sur les murs d'autres utilisateurs que soi
        return this.onOwner && !(this.onOwner instanceof Person && this.onOwner.uid !== this.$auth.user.sub)
      }
    },
    methods: {
      async updateVisibility() {
        if(!this.visibility || !this.targetFeedListUid || (this.visibility === this.publication.visibility && this.targetFeedListUid === this.publication.on)) {
          return
        }

        this.visibilityLoading = true
        try {
          let previousData = {
            pinned: this.pinned,
            feedListUid: this.publication.on
          }

          Publication.save(await Feeds.updatePublication(this.publication.uid, {
            visibility: this.visibility,
            feed_list: this.targetFeedListUid
          }))

          if (previousData.pinned && previousData.feedListUid !== this.publication.on) {
            let item = FeedItem.get(previousData.feedListUid+':'+this.publication.uid)
            item.$mutate(item => {
              item.pinned = false
            })

            if (await this.$confirm('La publication a été désépinglé. Souhaitez vous la réépingler sur le fil d\'actualité ' + this.onLabel + ' ?')) {
              await FeedList.get(this.targetFeedListUid).pinItem(item)
            }
          }
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'Le changement de visibilité n\'a pas pu être effectué'
          })
          throw e
        } finally {
          this.visibilityLoading = false
        }
      }
    }
  }
</script>

<template>
  <v-card v-bind="$attrs" class="p-rel">
    <v-btn v-if="previousStep" x-small fab dark color="secondary" class="close-modal first" @click="setStep(previousStep)">
      <font-awesome-icon fixed-width size="lg" :icon="['fal', 'arrow-left']" class="white--text" />
    </v-btn>
    <v-btn x-small fab dark color="primary" class="close-modal" @click="close()">
      <v-icon small color="white">$close</v-icon>
    </v-btn>

    <v-row class="ma-0 white">
      <modals-sidebar :title="title" />
      <v-col cols="11" class="pt-3">
        <slot />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  // Fonctionnement prévu pour être placé dans un dialog
  export default {
    name: 'CoreDialogStep',
    components: {ModalsSidebar},
    inject: ['setStep', 'close'],
    props: {
      step: {type: String, required: true},
      title: String,
      previousStep: String
    }
  }
</script>


<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <transition appear name="slide-x-transition">
    <div>
      <comment :comment="comment"
               :editable="comment.total === 0"
               @reply="openReplyField"
               @remove="$emit('remove')"
      />

      <div class="ml-3">
        <template v-if="canReply">
          <comment-reply v-if="comment.total === 0 || replies.length"
                         v-show="replyFieldVisible"
                         ref="replyField"
                         :mention="replyMention"
                         :focus-on-load="focusReply"
                         :on-reply="message => comment.reply(message)"
                         :comment="comment"
          />
        </template>

        <template v-if="repliesVisible">
          <comments-list :comments="replies"
                         :total="comment.total"
                         @load-more="loadMore"
          >
            <template v-slot="{comment: reply}">
              <comment :comment="reply"
                       :editable="reply.uid === replies[0]"
                       @remove="() => comment.removeReply(reply)"
                       @reply="() => openReplyField(reply.author)"
              />
            </template>
          </comments-list>
        </template>

        <template v-else-if="comment.total">
          <v-btn text type="button" class="text-caption grey--text reply-comments ml-11"
                 :disabled="loading"
                 @click="repliesVisible = true"
          >
            {{ comment.total }} {{ 'réponse' | pluralize(comment.total) }}
          </v-btn>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import Comment from '@/modules/comments/components/Comment'
  import CommentReply from '@/modules/comments/components/CommentReply'
  import CommentsList from '@/modules/comments/components/CommentsList'
  import {CommentsSection, Person} from '@/models'

  export default {
    name: 'CommentsThread',
    components: {CommentsList, CommentReply, Comment},
    props: {
      comment: Object,
      depth: {
        type: Number,
        default: 1
      }
    },
    data: function () {
      return {
        repliesVisible: false,
        replyFieldVisible: false,
        repliesLoaded: false,
        replyMention: null,
        focusReply: false,
        myNewReplies: [],
        loading: false
      }
    },
    computed: {
      canReply() {
        return this.$can('comment', CommentsSection.get(this.comment.section))
      },
      replies() {
        return this.comment.comments
      }
    },
    methods: {
      openReplyField(commentAuthor) {
        this.focusReply = true
        this.repliesVisible = true
        this.replyFieldVisible = true
        this.replyMention = commentAuthor ? Person.get(commentAuthor) : null

        if (this.$refs.replyField) {
          this.$nextTick(() => {
            this.$refs.replyField.focus(this.replyMention)
          })
        }
      },
      async loadMore(done) {
        done(await this.comment.loadComments())
      }
    }
  }
</script>


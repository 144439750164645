<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <form ref="form" @submit.prevent="submit">
    <v-card key="input" class="elevation-x px-3 py-1 mb-2">
      <v-textarea ref="publicationForm" v-model="message" required class="publication-form-text"
                  auto-grow clearable rows="5" maxlength="10000" lang="fr" spellcheck="true"
                  placeholder="Exprimez-vous ou partagez un lien avec tous vos contacts"
                  :messages="callbackMessages" :hide-details="!callbackMessages.length"
                  :loading="submitting" :disabled="submitting"
                  @keyup.enter="fetchOpenGraph" @blur="fetchOpenGraph"
                  @paste="onPaste" @change="onChange" @input="onInput"
      >
        <template v-slot:prepend-inner>
          <user-avatar :user="$auth.user" />
        </template>
      </v-textarea>
      <template v-if="openGraph || openGraphLoading">
        <v-row v-if="openGraphLoading">
          <v-col cols="6" offset="3">
            <v-progress-linear active indeterminate rounded height="6" class="my-6" />
          </v-col>
        </v-row>
        <open-graph :data="openGraph" :editable="true" @remove="removeOpenGraph"
                    @removeImage="removeOpenGraphImage"
        />
        <v-divider />
      </template>
      <files-upload-row ref="files"
                        :class="{ 'py-2': files.length > 0 || uploadingFiles }"
                        @update="updateFiles"
                        @uploading="uploadingFiles = true"
                        @uploaded="uploadingFiles = false"
      />
      <v-divider v-if="files.length > 0 || uploadingFiles" />
      <v-card-actions class="px-0">
        <v-btn v-if="!value" small icon title="Ajouter une image" @click="()=> $refs['files'].openFilesSelector()">
          <font-awesome-icon size="lg" :icon="['fad', 'image-polaroid']" class="green--text text--lighten-1" />
        </v-btn>
        <v-btn v-if="!value" small icon title="Ajouter une vidéo" @click="()=> $refs['files'].openFilesSelector()">
          <font-awesome-icon size="lg" :icon="['fad', 'film']" class="primary--text text--lighten-3" />
        </v-btn>
        <v-btn v-if="!value" small icon title="Ajouter un document" @click="()=> $refs['files'].openFilesSelector()">
          <font-awesome-icon size="lg" :icon="['fad', 'folder-open']" class="yellow--text text--darken-3" />
        </v-btn>
        <v-spacer />
        <feed-publication-visibility v-if="!privateOnly"
                                     editable
                                     button-style
                                     :feed-lists-uid="targetFeedListsUid"
                                     :target-feed-list-uid.sync="targetFeedListUid"
                                     :visibility.sync="visibility"
                                     class="mr-2"
        />
        <v-btn v-if="possibleAlert" small rounded color="warning"
               :loading="submitting" :disabled="submitting || uploadingFiles"
               @click="e=>submit(e, true)"
        >
          Créer une alerte
        </v-btn>
        <v-btn small rounded color="primary" type="submit" :loading="submitting"
               :disabled="submitting || uploadingFiles"
        >
          {{ value ? 'Enregistrer' : 'Publier' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </form>
</template>

<script>
  import { empty } from 'esyn-utils/src/utils/variables'
  import { findFirstUrlInText } from '@/utils/parsing'
  import * as Ws from '@/api/facebook-graph'
  import OpenGraph from '@/modules/feeds/components/FeedOpenGraph'
  import FileUploadMixin from '@/modules/files/mixins/file-upload'
  import FeedPublicationVisibility from '@/modules/feeds/components/Publication/FeedPublicationVisibility'
  import { FeedList, FeedListOwner } from '@/models'
  import FilesUploadRow from '@/modules/files/components/upload/FilesUploadRow'
  import UserAvatar from '@/modules/core/layout/UserAvatar'

  export default {
    name: 'FeedPublicationForm',
    components: {UserAvatar, FilesUploadRow, FeedPublicationVisibility, OpenGraph},
    mixins: [FileUploadMixin],
    inject: ['targetFeedListsUid'],
    props: {
      value: Object,
      posting: Boolean,
      allowAlert: Boolean
    },
    data() {
      return {
        message: this.value ? this.value.message : null,
        openGraph: this.value ? this.value.open_graph : null,
        files: this.value && Array.isArray(this.value.files) ? this.value.files : [],
        uploadedFiles: [],
        visibility: null,
        matchedUrl: null,
        openGraphLoading: false,
        callbackMessages: [],
        submitting: false,
        uploadingFiles: false,
        confirmLeaveIndex: null,
        focused: false,
        targetFeedListUid: this.targetFeedListsUid[0] || null
      }
    },
    computed: {
      possibleAlert() {
        return this.allowAlert && this.targetFeedListUid && this.$can('manage_alert', this.feedList(this.targetFeedListUid))
      },
      privateOnly() {
        return false // TODO check if feed list owner is a gang
      },
      feedList() {
        return uid => FeedList.get(uid)
      },
      feedListOwner() {
        return feedList => FeedListOwner.get(feedList.owner)
      }
    },
    beforeDestroy() {
      this.clearConfirmLeave()
    },
    methods: {
      updateFiles(uploadedFiles) {
        this.files = this.value && Array.isArray(this.value.files) ? this.value.files.concat(uploadedFiles) : uploadedFiles
      },
      clearConfirmLeave() {
        if (this.confirmLeaveIndex !== null) {
          this.$clearConfirmLeave(this.confirmLeaveIndex)
          this.confirmLeaveIndex = null
        }
      },
      onInput() {
        if (empty(this.message)) {
          this.matchedUrl = null
          this.openGraph = null
        }
      },
      onChange() { // TODO creer un renderless component pour ce comportement
        if (empty(this.message)) {
          this.clearConfirmLeave()
        } else if (this.confirmLeaveIndex === null) {
          this.confirmLeaveIndex = this.$confirmLeave('Vous n’avez pas encore fini votre publication. Voulez-vous quitter sans la terminer ?')
        }
      },
      submit(e, alert = false) {
        if(!this.$refs.form.reportValidity()) {
          return
        }

        this.submitting = true

        this.$emit('submit', {
          targetFeedListUid: this.targetFeedListUid,
          message: this.message,
          files: this.files.concat(this.uploadedFiles),
          open_graph: this.openGraph,
          visibility: this.visibility,
          alert
        }, (success) => {
          this.submitting = false

          if (success) {
            this.message = null
            if (this.$refs['files']) {
              this.$refs['files'].clear()
            }
          }
        })

        // Reset to initial state
        this.message = null
        this.openGraph = null
        if (this.$refs['files']) {
          this.$refs['files'].clear()
        }
        this.clearConfirmLeave()
      },
      removeOpenGraph() {
        this.openGraph = null
      },
      removeOpenGraphImage() {
        this.$delete(this.openGraph, 'og:image')
        this.$delete(this.openGraph, 'og:ratio')
      },
      onPaste() {
        setTimeout(this.fetchOpenGraph, 100)
      },
      handleFetchOpenGraphError(url, e) {
        this.$root.$emit('snackbar:open', {
          snackbar: () => import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Erreur de récuperation',
          subtitle: 'Impossible de récupérer le contenu du site'
        })
        // eslint-disable-next-line no-console
        console.error(e)
      },
      async fetchOpenGraph() {
        if (this.openGraph || this.openGraphLoading) {
          return
        }

        let url = findFirstUrlInText(this.message)
        if (!url || url === this.matchedUrl) {
          return
        }

        this.openGraphLoading = true
        this.matchedUrl = url

        let data
        try {
          data = (await Ws.url(url)).data
        } catch (e) {
          this.handleFetchOpenGraphError(url, e)
        }

        if (data.error) {
          this.handleFetchOpenGraphError(url, new Error(data.error.type + ' ' + data.error.code + ' - ' + data.error.message))
          return
        }

        try {
          this.openGraph = await new Promise((resolve) => {
            if (typeof data['image'] !== 'undefined' && typeof data['image'][0] !== 'undefined') {
              let img = new Image()
              img.src = data['image'][0]['url']
              img.onload = function () {
                data['image'][0]['ratio'] = img.height / img.width
                resolve(data)
              }
            } else {
              resolve(data)
            }

            this.openGraphLoading = false
          })
        } catch (e) {
          this.handleFetchOpenGraphError(url, e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .publication-form-text textarea {
    padding-top: 6px;
    padding-left: 3px;
  }
</style>

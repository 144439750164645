<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <ul>
    <li>Compte patient</li>
  </ul>
</template>

<script>
  export default {
    name: 'PatientData',
    props: {
      type: String,
      popData: Object
    }
  }
</script>

import * as Feeds from '@/modules/feeds/api'
import { FeedListOwner, Person, Team, Space } from '@/models'

export default {
  created(){
      this.$store.dispatch('feeds/fetchSubscriptions')
  },
  data(){
    return{
      pinning: false
    }
  },
  computed: {
    feedListOwner() { // L'entité où a été écris la publication
      return list => FeedListOwner.get(list.owner)
    },
    subscriptions() {
      return this.$store.state.feeds.subscriptions
    },
    isSubscribedToFeed() {
      return feedUid => {
        return this.subscriptions ? Object.keys(this.subscriptions).indexOf(feedUid) > -1 : []
      }
    },
    subscriptionsActions() {
      return feedLists => {
        let actions = []
        let feedListUids = []
        for (let feedList of feedLists) {
          let owner = this.feedListOwner(feedList)
          // Avoid duplicated subscription action and remove current user
          if(feedListUids.includes(feedList.uid) || owner instanceof Space || owner instanceof Team ||
            (owner instanceof Person && owner.uid === this.$auth.user.sub)) {
            continue
          }

          feedListUids.push(feedList.uid)

            let isSubscribed = this.isSubscribedToFeed(feedList.personal_feed)
            let label = isSubscribed ? 'Se désabonner ' : 'S\'abonner '

            switch (owner.type) {
              case 'space':
                label += (isSubscribed ? 'de' : 'à') + ' l\'espace'
                break
              case 'establishment':
                label += (isSubscribed ? 'de' : 'à') + ' l\'établissement'
                break
              case 'team':
                label += (isSubscribed ? 'du' : 'au') + ' groupe'
                break
              default:
                label += isSubscribed ? 'de' : 'à'
            }
            label += ' ' + owner.name

            actions.push({
              label,
              action: async () => {
                try {
                  this.$emit('loading')

                  await this.$store.dispatch(isSubscribed ? 'feeds/unsubscribe' : 'feeds/subscribe', feedList)

                  this.$store.commit('layout/openSnackbar', {
                    text: 'Abonnement ' + (isSubscribed ? 'retiré' : 'ajouté'),
                    color: 'success'
                  })
                } catch (e) {
                  this.$handleApiError(e)
                } finally {
                  this.$emit('loaded')
                }
              }
            })

        }
        return actions
      }
    }
  },
  methods: {
    async hide(publication) {
      try {
        this.$emit('loading')
        await Feeds.hideItem(this.feedList.uid, publication.uid)
        this.item.$delete()

        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Publication retirée',
          subtitle: 'Publication retirée avec succès.'
        })
      } catch (e) {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Une erreur est survenue',
          subtitle: 'Une erreur est survenue veuillez réessayer.'
        })
        throw e
      } finally {
        this.$emit('loaded')
      }
    },
    async updateAlertFlag(value) {
      try {
        this.$emit('loading')

        await this.publication.update({alert: value})
      } catch (e) {
        this.$handleApiError(e)
      } finally {
        this.$emit('loaded')
      }
    },
    async pin() {
      this.$emit('loading')
      this.pinning = true
      try {
        await this.feedList.pinItem(this.item)
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Publication épinglée',
          subtitle: 'Publication épinglée avec succès.'
        })

      } catch (e) {
        this.$handleApiError(e)
      } finally {
        this.$emit('loaded')
        this.pinning = false
      }
    },
    async unpin() {
      this.$emit('loading')
      this.pinning = true
      try {
        await this.feedList.unpinItem(this.item)
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Publication désépinglée',
          subtitle: 'Publication désépinglée avec succès.'
        })
      } catch (e) {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Une erreur est survenue',
          subtitle: 'Une erreur est survenue veuillez réessayer.'
        })
        throw e
      } finally {
        this.$emit('loaded')
        this.pinning = false
      }
    },
    async deletePublication(publication) {
      if (!await this.$confirm('Êtes-vous sûr de vouloir supprimer la publication ?')) {
        return
      }

      this.$emit('loading')

      try {
        await Feeds.deleteItem(publication.on, publication.uid)
        this.item.$delete()
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'success',
          title: 'Publication supprimée',
          subtitle: 'Publication supprimée avec succès.'
        })
      } catch (e) {
        this.$root.$emit('snackbar:open', {
          snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
          type: 'error',
          title: 'Une erreur est survenue',
          subtitle: 'Une erreur est survenue veuillez réessayer.'
        })
        throw e
      } finally {
        this.$emit('loaded')
      }
    }
  }
}

<template>
  <app-actions :actions="actions" />
</template>

<script>
  import AppActions from '@/modules/core/layout/app/AppActions'
  import {FeedItem, FeedList, FeedListOwner, Publication} from '@/models'
  import actionsMixin from '@/modules/feeds/mixins/feed-publication-actions'

  export default {
    name: 'FeedPublicationSharingActions',
    components: {AppActions},
    mixins: [actionsMixin],
    props: {
      feedList: FeedList,
      item: FeedItem,
      publication: Publication,
      sharedPublication: Publication
    },
    computed: {
      by() { // La feed list de l'user ayant écris la publication partagé
        return FeedList.get(this.sharedPublication.by)
      },
      byOwner() { // Le user ayant écris la publication partagé
        return FeedListOwner.get(this.by.owner)
      },
      on() { // La feed list de l'entité où a été écris la publication
        return this.sharedPublication.on ? FeedList.get(this.sharedPublication.on) : null
      },
      onOwner() { // L'entité où a été écris la publication
        return this.on ? FeedListOwner.get(this.on.owner) : null
      },
      sharedBy() { // La feed list de l'user ayant fait le partage
        return FeedList.get(this.publication.by)
      },
      sharedByOwner() { // Le user ayant fait le partage
        return FeedListOwner.get(this.sharedBy.owner)
      },
      actions() {
        let actions = []

        if (this.$can('share', this.sharedPublication)) {
          actions.push({
            label: 'Partager',
            action: () => {
              this.$emit('share')
            }
          })
        }

        if (this.$can('update', this.publication)) {
          actions.push({
            label: 'Editer', action: () => {
              this.$emit('update')
            }
          })
        }

        if (this.item.pinned && this.$can('unpin', this.feedList)) {
          actions.push({label: 'Desepingler la publication', action: this.unpin})
        } else if (!this.item.pinned && this.$can('pin', this.feedList)) {
          actions.push({label: 'Epingler la publication', action: this.pin})
        }

        if (this.$can('manage_alert', this.feedList)) {
          actions.push({
            label: this.publication.alert ? 'Ne plus marquer comme alerte' : 'Marquer comme alerte',
            action: () => this.updateAlertFlag(!this.publication.alert)
          })
        }

        // L'utilisateur peux masquer toutes les publications sur son mur qui proviennent de ses abonnements
        // On n'utilise pas casl ici car la librairie ne supporte pas plusieurs paramètres (feedlist, publication)
        if (this.$can('remove', this.publication)) {
          actions.push({label: 'Supprimer mon partage', action: () => this.deletePublication(this.publication)})
        } else if (this.$can('remove', this.sharedPublication)) {
          actions.push({
            label: 'Supprimer ma publication',
            action: () => this.deletePublication(this.sharedPublication)
          })
        } else if (this.$can('remove', this.feedList)) {
          actions.push({label: 'Masquer la publication', action: () => this.hide(this.sharedPublication)})
        }

        actions = actions.concat(this.subscriptionsActions([this.sharedBy,this.by, this.on]))

        return actions
      }
    }
  }
</script>

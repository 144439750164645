/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import {empty} from 'esyn-utils/src/utils/variables'

const urlRegex = new RegExp('(http|https|ftp|ftps)\\:\\/\\/[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]{2,3}(\\/\\S*)?')

export function findFirstUrlInText(text) {
  let result = urlRegex.exec(text)
  if (empty(result)) {
    return null
  }
  result = result[0]

  if (result.slice(-1) === '.') {
    result = result.slice(0, -1)
  }

  return result
}

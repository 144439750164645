<template>
  <v-dialog persistent :value="true" max-width="600">
    <v-card class="p-rel">
      <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
        <v-icon small color="white">$close</v-icon>
      </v-btn>
      <v-row class="ma-0 white" align-content="space-between">
        <modals-sidebar title="Modifier la publication" />
        <v-col cols="11" class="pt-3 align-self-start">
          <form-category title="Publication" subtitle="Modifier le contenu de ma publication" />
          <publication-form
            :value="publicationData"
            @submit="update"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  import PublicationForm from '@/modules/feeds/components/FeedPublicationForm'
  import {Publication} from '@/models'
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import FormCategory from '@/modules/core/layout/FormCategory'

  export default {
    name: 'FeedPublicationUpdateDialog',
    components: {PublicationForm, ModalsSidebar, FormCategory},
    inject:['eventBus'],
    props: {
      publication: Publication
    },
    data() {
      return {
        publicationData: {
          message: this.publication.message,
          visibility: this.publication.visibility,
          open_graph: this.publication.openGraph,
          medias: this.publication.medias
        }
      }
    },
    methods: {
      async update(data, done) {
        try {
          await this.publication.update({
            feed_list: data.targetFeedListUid,
            message: data.message,
            visibility: data.visibility,
            files: data.medias
          })

          this.$emit('update', this.publication)
          this.$emit('close')

          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Publication editée',
            subtitle: 'La publication a été éditée avec succès.'
          })

          done(true)
        } catch (e) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue',
            subtitle: 'La publication n\'a pas pu être éditée.'
          })
          done(false)
          throw e
        }
      }
    }
  }
</script>

<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container v-if="data && (image || (data.url && data.title) ) ">
    <v-row class="open-graph" :class="{extended: isExtendedImage}">
      <v-col v-if="image" cols="12" md="3" class="image flex-fill">
        <img alt="opengraph image" :src="image.url" :title="data.title">
        <button v-if="editable" type="button" class="btn btn-light remove" @click="$emit('removeImage')">
          <i class="fe fe-x-square" />
        </button>
      </v-col>

      <v-col cols="12" md="9" class="content">
        <div>
          <div v-if="data.title && data.url" class="title">
            <a :href="data.url" target="_blank">{{ data.title }}</a>
          </div>
          <div v-if="data.description" class="description">
            {{ data.description }}
          </div>
          <div v-if="data.url && data.site_name" class="">
            <a :href="data.url" target="_blank">{{ data.site_name }}</a>
          </div>
        </div>

        <div v-if="editable">
          <button type="button" class="btn btn-light remove" @click="$emit('remove')">
            <v-icon>$close</v-icon>
          </button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'FeedOpenGraph',
    props: {
      data: {
        type: Object
      },
      editable: {
        type: Boolean
      }
    },
    computed: {
      image() {
        return this.data && this.data.image && this.data.image.length ? this.data.image[0] : null
      },
      isExtendedImage() {
        return this.image && !isNaN(this.image.ratio) && parseFloat(this.image.ratio) < 0.65
      }
    }
  }
</script>

<style scoped lang="scss">
  .open-graph {
    border: 1px solid #ccc;
    border-radius: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;

    .image {
      position: relative;

      img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }

      .remove {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .content {
      .title {
        a {
          color: #333;
        }

        text-align: left;
        font-weight: bold;
        margin-bottom: 0.7em;
      }

      .description {
        font-size: 0.9em;
        color: #666;
      }
    }
  }
</style>

<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="comment pa-3 pt-0" :class="{loading}">
    <v-row>
      <v-col>
        <app-author :user="author" :date="comment.creationDate" />
      </v-col>
      <v-col class="text-right">
        <app-actions :actions="actions" />
      </v-col>
    </v-row>

    <comment-reply v-if="editing" ref="edit" :on-reply="update" :value="comment.message" />
    <div v-else class="content text-display" :class="{'font-italic' : !comment.message}">
      {{ comment.deleted ? 'Commentaire supprimé' : comment.message }}
    </div>

    <a class="answer-link" href="#" @click.prevent="$emit('reply')">Répondre</a>
    <a v-if="editing" class="answer-link red--text text--darken-2" :class="{ editing: editing }"
       @click="editing = false"
    >Annuler</a>
  </div>
</template>

<script>
  import CommentReply from '@/modules/comments/components/CommentReply'
  import AppAuthor from '@/modules/core/layout/app/AppAuthor'
  import AppActions from '@/modules/core/layout/app/AppActions'
  import {Comment, Person} from '@/models'

  export default {
    name: 'Comment',
    components: {AppActions, AppAuthor, CommentReply},
    props: {
      comment: Comment,
      editable: {type: Boolean}
    },

    data() {
      return {
        editing: false,
        loading: false
      }
    },

    computed: {
      author() {
        return Person.get(this.comment.author)
      },
      actions() {
        let actions = []

        if (this.editable && this.$can('update', this.comment)) {
          actions.push({
            label: 'Editer', action: () => {
              this.editing = true
              this.$nextTick(() => {
                this.$refs.edit.focus()
              })
            }
          })
        }

        if (this.$can('delete', this.comment)) {
          actions.push({
            label: 'Supprimer', action: async () => {
              this.loading = true
              try {
                await this.comment.remove()
                if (!this.comment.total) {
                  this.$emit('remove')
                }
              } catch (e) {
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'error',
                  title: 'Une erreur est survenue',
                  subtitle: 'Le commentaire n\'a pas pu être supprimé'
                })
                throw e
              } finally {
                this.loading = false
              }
            }
          })
        }

        return actions.length ? actions : null
      }
    },
    methods: {
      async update(message) {
        try {
          await this.comment.update(message)
        } catch (e) {
          if (e.response && e.response.data.type === 'non_editable_comment') {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'error',
              title: 'Erreur d\'édition',
              subtitle: 'Ce commentaire a reçu des réponses et ne peut plus être édité'
            })
          } else {
            throw e
          }
        }

        this.editing = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .comment {
    .content {
      margin: 6px 5px 0 47px;
      white-space: pre-wrap;
    }
    .answer-link {
      margin-left: 51px;
      font-size: 0.85em;
      &.editing {
        margin-left: 10px;
      }
    }
  }
  .comment.loading {
    opacity: 0.5;
  }
</style>

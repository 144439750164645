<template>
  <v-dialog :value="true" v-bind="$attrs">
    <core-stepper v-model="localValue">
      <slot />
    </core-stepper>
  </v-dialog>
</template>

<script>
  import CoreStepper from '@/modules/core/components/CoreStepper'

  export default {
    name: 'CoreDialogStepper',
    components: {CoreStepper},
    provide() {
      return {
        setStep: (value) => {
          this.$set(this, 'localValue', value)
        },
        close: () => {
          this.$emit('close')
        }
      }
    },
    props: {
      value: String
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    }
  }
</script>

<style scoped>

</style>

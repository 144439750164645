<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div class="comments-section container-fluid">
    <template v-if="$can('comment', commentsSection)">
      <comment-reply v-show="commentsSection.total === 0 || commentsSection.comments.length"
                     ref="reply"
                     :on-reply="message => commentsSection.reply(message)"
      />
    </template>

    <v-lazy>
      <comments-list :comments="commentsSection.comments"
                     :total="commentsSection.totalDirect"
                     @load-more="loadMore"
      >
        <template v-slot="{comment}">
          <comment-thread :comment="comment"
                          @remove="()=>commentsSection.removeComment(comment)"
          />
        </template>
      </comments-list>
    </v-lazy>
  </div>
</template>

<script>
  import CommentThread from '@/modules/comments/components/CommentsThread'
  import CommentReply from '@/modules/comments/components/CommentReply'
  import CommentsList from '@/modules/comments/components/CommentsList'
  import { CommentsSection } from '@/models'

  export default {
    name: 'CommentsSection',
    components: {CommentsList, CommentReply, CommentThread},
    props: {
      uid: String
    },
    data() {
      return {
        newerItemsLoading: false,
        olderItemsLoading: false
      }
    },
    computed: {
      commentsSection() {
        return CommentsSection.get(this.uid)
      }
    },
    methods: {
      open() {
        this.$refs.reply.focus()
      },
      async loadMore(done) {
        done(await this.commentsSection.loadComments())
      }
    }
  }
</script>

<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <ul>
    <li>
      Adresse : <span class="font-weight-medium secondary--text text--darken-1">
        {{ popData.slug }}.medicalspace.fr</span>
    </li>
    <li v-if="membershipData">
      Membre depuis le <span class="font-weight-bold">
        {{ $dayjs(membershipData.creation_date.birth_date).format('D MMMM YYYY') }}
      </span>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'SpaceData',
    props: {
      popData: Object,
      membershipData: Object
    }
  }
</script>
